import React, {useState} from "react";
import {locale} from "../../../utils/locale";
import categoryLinkService from "../../../services/category/categoryLinkService";
import variantService from "../../../services/product/variantService";
import cookieService from "../../../services/cookie/cookieService";
import apiInternal from "../../../utils/apiInternal";
import Image from "next/image";
import {stripHtml} from "string-strip-html";
import i18next from "i18next";
import ProductSlider from "../../slider/Product";
import Link from "next/link";

export default function NavigationMainDrawer({category}: any) {
    const [isOpen, setIsOpen] = useState(false);
    const [navigation, setNavigation] = useState([]);
    const [blogs, setBlogs] = useState([]);
    const [brands, setBrands] = useState([]);
    const [products, setProducts] = useState([]);

    const toggleDrawer = () =>
        (event: React.KeyboardEvent | React.MouseEvent) => {
            if (
                event &&
                event.type === 'keydown' &&
                ((event as React.KeyboardEvent).key === 'Tab' ||
                    (event as React.KeyboardEvent).key === 'Shift')
            ) {
                return;
            }

            const open = !isOpen;
            setIsOpen(open);
            if (open) getNavigation().then();
        };

    async function getNavigation() {
        if (!category.childId) return;
        const resCategories = await apiInternal().post('/api/v2/navigation/main/sub', {id: category.id});
        if (!resCategories.data) return;
        setNavigation(resCategories.data);
        if (category.menuSpecial) getSpecialMenu();
    }

    async function getSpecialMenu() {
        return Promise.all([
            getBrands(),
            getBlogs(),
            getProducts()
        ]);
    }

    async function getBrands() {
        const resBrands = await apiInternal().post('/api/v2/navigation/main/special/brands');
        setBrands(resBrands.data);
    }

    async function getBlogs() {
        const resBlogs = await apiInternal().post('/api/v2/navigation/main/special/blogs', {locale: i18next.language});
        setBlogs(resBlogs.data);
    }

    async function getProducts() {

        const resProducts = await apiInternal().post('/api/v2/navigation/main/special/products', {
            locale: locale(i18next.language),
            customerGroup: cookieService.getCustomerGroup()
        });

        await variantService.getVariants(resProducts.data, true, cookieService.getCustomerGroup(), i18next.language);
        setProducts(resProducts.data);
    }

    function truncate(str: string, maxStrLength: number) {
        return (str.length > maxStrLength) ? str.slice(0, maxStrLength - 1) + '...' : str;
    }

    return (
        <li
            onMouseLeave={() => setIsOpen(false)}
            onMouseEnter={toggleDrawer()}>
            <Link href={categoryLinkService.getLink(category)} className={"nav-link main-nav-link px-3 link-secondary"}>
                <span>{category.name}</span>
            </Link>
            {category.menuSpecial ? (
                <div className={`nav-sub fw-normal ${isOpen ? 'd-block' : 'd-none'}`}>
                    <div className="container">
                        <div className="row">
                            <div className="grid row-2 new-navigation">
                                {navigation.map((c1: any) => (
                                    <React.Fragment key={c1.id}>
                                        {(c1.menuType === 'new_brand') ? (
                                            <div className="d-flex flex-column">
                                                <p role="button">
                                                    <Link href={categoryLinkService.getLink(c1)}
                                                          prefetch={false}
                                                          onClick={() => setIsOpen(false)}>
                                                        <b>{c1.name}</b>
                                                    </Link>
                                                </p>
                                                {brands.map((brand: any) => (
                                                    <Link href={`/${i18next.language}/${brand.link}`}
                                                          key={brand.id}
                                                          prefetch={false}
                                                          onClick={() => setIsOpen(false)} className="brand-image-link">
                                                        <Image src={brand.image} className={'w-100 h-auto brand-image'}
                                                               height={200}
                                                               width={220}
                                                               alt={brand.name}/>
                                                    </Link>
                                                ))}
                                                <Link href={categoryLinkService.getLink(c1)}
                                                      prefetch={false}
                                                      onClick={() => setIsOpen(false)} className=" special-menu-more">
                                                    <b>{i18next.t('general.showMore').toString()}</b>
                                                </Link>
                                            </div>
                                        ) : (c1.menuType === 'new_product') ? (
                                            <div className="d-flex flex-column">
                                                <p role="button">
                                                    <Link href={categoryLinkService.getLink(c1)}
                                                          prefetch={false}
                                                          onClick={() => setIsOpen(false)}>
                                                        <b>{c1.name}</b>
                                                    </Link>
                                                </p>
                                                <div className="new-menu-product-slider" data-per-page={1}>
                                                    <ProductSlider products={products} perPage={1} container={false}
                                                                   pt={'pt-0'} mt={'mt-0'} informationOnly={false}/>
                                                </div>

                                                <Link href={categoryLinkService.getLink(c1)}
                                                      prefetch={false}
                                                      onClick={() => setIsOpen(false)} className=" special-menu-more">
                                                    <b>{i18next.t('general.showMore').toString()}</b>
                                                </Link>
                                            </div>
                                        ) : (c1.menuType === 'new_blog') ? (
                                            <div className="d-flex flex-column">
                                                <p role="button">
                                                    <Link href={categoryLinkService.getLink(c1)}
                                                          prefetch={false}
                                                          onClick={() => setIsOpen(false)}>
                                                        <b>{c1.name}</b>
                                                    </Link>
                                                </p>

                                                {blogs.map((blog: any) => (
                                                    <Link href={`/${i18next.language}/${blog.link}`}
                                                          key={blog.id}
                                                          prefetch={false}
                                                          onClick={() => setIsOpen(false)} className="pb-2">

                                                        <Image src={blog.image}
                                                               height={200}
                                                               width={200}
                                                               alt={''}
                                                               className="d-block s new-blog-div w-100 h-auto"/>

                                                        <p className="mb-0 new-blog-text p-1">
                                                            {truncate(blog.title, 50)}
                                                        </p>
                                                    </Link>
                                                ))}
                                                <Link href={categoryLinkService.getLink(c1)}
                                                      onClick={() => setIsOpen(false)}
                                                      prefetch={false}
                                                      className="mt-2  special-menu-more">
                                                    <b>{i18next.t('general.showMore').toString()}</b>
                                                </Link>
                                            </div>
                                        ) : (c1.menuType === 'banner') ? (
                                            <div className="d-flex flex-column">
                                                <p role="button">
                                                    <Link href={categoryLinkService.getLink(c1)}
                                                          prefetch={false}
                                                          onClick={() => setIsOpen(false)}>
                                                        <b>{c1.name}</b>{c1.icon}
                                                    </Link>
                                                </p>
                                                <Link href={c1.menuLink} prefetch={false} onClick={() => setIsOpen(false)}>
                                                    <Image src={c1.menuImage}
                                                           height={270}
                                                           width={270}
                                                           alt={c1.name}
                                                           className="d-block special-banner w-100 h-auto"/>
                                                    <p className="mt-2">
                                                        <b>{stripHtml(c1.menuTitle).result}</b>
                                                    </p>
                                                    <p>{stripHtml(c1.menuText).result}</p>
                                                </Link>

                                                <Link href={categoryLinkService.getLink(c1)}
                                                      prefetch={false}
                                                      onClick={() => setIsOpen(false)}
                                                      className="mt-2  special-menu-more">
                                                    <b>{i18next.t('general.showMore').toString()}</b>
                                                </Link>
                                            </div>
                                        ) : ''}
                                    </React.Fragment>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            ) : category.childId ? (
                <div className={`nav-sub fw-normal ${isOpen ? 'd-block' : 'd-none'}`}>
                    <div className="container">
                        <div className="row">
                            <div className="col-9">
                                <div className="d-flex flex-row">
                                    {navigation.map((c1: any, index: number) => (
                                        <div className="ps-2 pb-2 flex-fill"
                                             key={`${c1.id}-${index}`}>
                                            <Link href={categoryLinkService.getLink(c1)}
                                                  key={`link-${c1.id}-${index}`}
                                                  // scroll={false}
                                                  onClick={() => setIsOpen(false)}
                                                  prefetch={false}>
                                                <p role="button">
                                                    <b>{c1.name}</b>
                                                </p>
                                            </Link>

                                            {('children' in c1) && (
                                                c1.children.map((c2: any, index: number) => (
                                                    <Link href={categoryLinkService.getLink(c2)}
                                                          key={`${c2.id}-${index}`}
                                                          // scroll={false}
                                                          onClick={() => setIsOpen(false)}
                                                          prefetch={false}>
                                                        <p role="button">{c2.name}</p>
                                                    </Link>
                                                ))
                                            )}
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="col-3">
                                <Link href={category.menuLink} prefetch={false} onClick={() => setIsOpen(false)}>
                                    <div>
                                        <Image src={category.menuImage}
                                               height={540}
                                               width={540}
                                               alt={category.name}
                                               className="d-block w-100 h-auto"/>
                                        <p className="mt-2">
                                            <b>{stripHtml(category.menuTitle).result}</b>
                                        </p>
                                        <p>{stripHtml(category.menuText).result}</p>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            ) : <></>}
        </li>
    )
}
