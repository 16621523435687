import HeaderNavigation from "./Header/Navigation";
import React, {useEffect, useState} from "react";
import i18next from "i18next";
import Link from "../link/Link";
import {useRouter} from "next/router";
import Image from "next/image";
import languageService from "../../services/languageService";
import NavigationOffcanvasMain from "../navigation/offcanvas/Main";
import Search from "../search/Search";

interface Props {
    logo?: any
}

export default function Header({logo}: Props) {
    const router = useRouter();
    const [isVisible, setIsVisible] = useState(true);
    let lastPos: number = 0;

    useEffect(() => {
        window.addEventListener('scroll', scrollHandler);
        return () => {
            window.removeEventListener("scroll", scrollHandler);
        };
    }, [])

    const scrollHandler = () => {
        setTimeout(() => {
            const currentPos = window.scrollY;

            if (currentPos < lastPos) {
                setIsVisible(true);
            }
            if (currentPos > 80 && currentPos > lastPos) {
                setIsVisible(false);
            }

            lastPos = currentPos;
        }, 20)
    }

    const desktopLogo = (): string => {
        try {
            return logo.desktopUrl;
        }
        catch(e) {
            return '/images/logo.svg';
        }
    }

    const mobileLogo = (): string => {
        try {
            return logo.mobileUrl;
        }
        catch(e) {
            return 'https://cdn.perfecthair.ch/media/vector/9a/64/cd/perfecthair-logo-picturemark-rgb.svg';
        }
    }

    return (
        <>
            <header className={`header ${isVisible ? 'show' : 'hide'}`}>
                <div className="usp d-none d-xl-block">
                    <div className="container pt-1 pb-1">
                        <div className="row">
                            <div className="col-8">
                                <div className="row sup">
                                    <div className="col">
                                        <i className="fa-sharp fa-solid fa-expand"></i>
                                        <span className={"ms-2"}>{i18next.t('usp.wideSelection').toString()}</span>
                                    </div>
                                    <div className="col">
                                        <i className="fa-sharp fa-solid fa-tag"></i>
                                        <span className={"ms-2"}>{i18next.t('usp.attractivePrices').toString()}</span>
                                    </div>
                                    <div className="col">
                                        <i className="fa-sharp fa-light fa-truck-bolt"></i>
                                        <span className={"ms-2"}>{i18next.t('usp.fastDelivery').toString()}</span>
                                    </div>
                                    <div className="col">
                                        <i className="fa-sharp fa-light fa-shield-check"></i>
                                        <span className={"ms-2"}>{i18next.t('usp.secureOrder').toString()}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-4">
                                <ul className="list-group list-group-horizontal list-unstyled sup float-end">
                                    <li className="me-2">
                                    <span onClick={() => languageService.setLanguage('de', router)}
                                          role={"button"}
                                          className={`d-block ps-2 pe-2 dark ${i18next.language === 'de' && 'fw-bold'}`}>
                                        DE
                                    </span>
                                    </li>
                                    <li className="me-2">
                                    <span onClick={() => languageService.setLanguage('fr', router)}
                                          role={"button"}
                                          className={`d-block ps-2 pe-2 dark ${i18next.language === 'fr' && 'fw-bold'}`}>
                                        FR
                                    </span>
                                    </li>
                                    <li className="me-2">
                                    <span onClick={() => languageService.setLanguage('en', router)}
                                          role={"button"}
                                          className={`d-block ps-2 pe-2 dark ${i18next.language === 'en' && 'fw-bold'}`}>
                                        EN
                                    </span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container pt-3 ps-3 pe-3 pb-1">
                    <div className="row">
                        <div className="col-4 col-md-3">
                            <div className="d-flex flex-row">
                                <NavigationOffcanvasMain/>
                                <Link href={`/${i18next.language}`} passHref>
                                    {/* Original Desktop Logo */}
                                    <Image src={desktopLogo()} alt="Logo" width={300} height={50} className="d-none d-lg-block logo w-100" role="button"/>

                                    {/*  Christmas Logo Desktop  */}
                                    {/*{i18next.language === 'de' && (*/}
                                    {/*    <Image src="https://cdn.perfecthair.ch/media/image/23/5d/05/PFH-Weihnachten-DE.png" alt="Logo" width={300} height={50}*/}
                                    {/*           className="d-none d-lg-block logo w-100" role="button"/>*/}
                                    {/*)}*/}

                                    {/*{i18next.language === 'en' && (*/}
                                    {/*    <Image src="https://cdn.perfecthair.ch/media/image/18/36/36/PFH-Weihnachten-EN.png" alt="Logo" width={300} height={50}*/}
                                    {/*           className="d-none d-lg-block logo w-100" role="button"/>*/}
                                    {/*)}*/}

                                    {/*{i18next.language === 'fr' && (*/}
                                    {/*    <Image src="https://cdn.perfecthair.ch/media/image/78/26/f6/PFH-Weihnachten-FR.png" alt="Logo" width={300} height={50}*/}
                                    {/*           className="d-none d-lg-block logo w-100" role="button"/>*/}
                                    {/*)}*/}
                                </Link>
                                <Link href={`/${i18next.language}`} passHref>

                                    {/* Original Mobile Logo */}
                                    <Image
                                        src={mobileLogo()}
                                        width={40} height={40}
                                        alt="Logo" className="d-lg-none logo w-100" style={{maxWidth: '40px'}}
                                        role="button"/>

                                    {/*<Image*/}
                                    {/*    src="https://cdn.perfecthair.ch/media/image/b3/3d/e1/christmas-logo-mobile.png"*/}
                                    {/*    width={40} height={40}*/}
                                    {/*    alt="Logo" className="d-lg-none logo w-100" style={{maxWidth: '40px'}}*/}
                                    {/*    role="button"/>*/}
                                </Link>
                            </div>
                        </div>
                        <div className="order-2 order-md-1 col-12 col-md-5">
                            <Search/>
                        </div>
                        <div className="order-1 order-md-2 col-8 col-md-4 pb-0 pb-md-0">
                            <HeaderNavigation/>
                        </div>
                    </div>
                </div>
            </header>
        </>
    )
}
