import React, {useEffect} from "react";
import Link from "next/link";
import {useDispatch, useSelector} from "react-redux";
import messageService from "../../services/message/messageService";
import i18next from "i18next";
import apiFetch from "../../utils/apiFetch";
import {setBxData, setLoadedLanguage} from "../../stores/promoBanner/promoBannerSlice";
import {State} from "../../stores/store";
import {convertBxAttributes} from "../../utils/bxAttributes";
import single from "../../utils/single";
import {BxRichText} from "../boxalino/RichText";

function BannerContent({ block }: { block: any }) {
    const dispatch = useDispatch();

    async function copy(code: string) {
        await navigator.clipboard.writeText(code);
        messageService.success(i18next.t('copy.codeSuccess'), dispatch);
    }

    function getValue(key: string) {
        return single(block[key]) ?? '';
    }

    const text = getValue('text');
    const link = getValue('link');
    const code = getValue('code');

    if (code) {
        return <div role={"button"}>
            <BxRichText content={text} />
            <span> | </span>
            <span className={"text-decoration-underline"}
                  onClick={() => copy(code)}>{i18next.t('copy.codeTitle').toString()}</span>
        </div>;
    } else if (link) {
        return (
            <Link href={link}>
                <BxRichText content={text} />
                <i className="ms-2 fa-regular fa-up-right-from-square"></i>
            </Link>
        );
    } else {
        return <BxRichText content={text} />;
    }
}

interface Props {
    lightColor?: boolean
}

export default function PromotionAdBanner({lightColor = false}: Props) {
    const dispatch = useDispatch();
    const bxData = useSelector((state: State) => state.promoBannerState.bxData);
    const loadedLanguage = useSelector((state: State) => state.promoBannerState.loadedLanguage);

    async function loadData() {
        dispatch(setLoadedLanguage(i18next.language));
        const response = await apiFetch('/api/v2/bx/banner', {locale: i18next.language, url: window.location.href});
        const bxData = await response.json();
        dispatch(setBxData(bxData));
    }

    useEffect(() => {
        if (loadedLanguage === i18next.language) {
            return;
        }
        void loadData();
    }, [bxData, loadedLanguage, i18next.language]);

    const wrappingBlock = bxData?.blocks?.[0];
    const bannerBlock = wrappingBlock?.blocks[0];

    return <div className={`${lightColor ? 'bg-primary-light' : 'bg-primary'} pt-2 pb-2 promo-banner`}>
        <div className={`container text-center align-middle bx-ad-banner ${lightColor ? 'text-primary' : 'text-white'}`}>
            {wrappingBlock &&
                <div {...convertBxAttributes(wrappingBlock)}>
                    {bannerBlock && <div {...convertBxAttributes(bannerBlock)}><BannerContent block={bannerBlock}/></div>}
                </div>
            }
        </div>
    </div>;
}
