import i18next from "i18next";
import React, {useEffect, useState} from "react";
import {roundPrice} from "../../../utils/roundPrice";

interface Props {
    cart?: any,
    minShippingFree?: number,
    itemShippingFree?: boolean,
    shippingFree?: number,
    isLoading?: boolean
}

export default function CartShippingFreeProgressBar({cart, minShippingFree = 0, shippingFree = 160, itemShippingFree, isLoading}: Props) {
    const [text, setText] = useState('');
    const [bar, setBar] = useState(0);
    const [color, setColor] = useState('');

    useEffect(() => {
        if (!cart.total) return;

        calcShippingFree()
    }, [cart.total.subTotal, isLoading]);

    function calcShippingFree() {
        let percent = shippingFree === 0 ? 100 : (cart.total.subTotal / shippingFree) * 100;
        if (itemShippingFree) percent = 100;

        setBar(percent);
        setColor(percent >= 100 ? '#4d9781' : '#FEE370');
        setText(percent >= 100 ? '' : ` (${i18next.t('general.missing').toString()} ${roundPrice(shippingFree - cart.total.subTotal)})`)
    }

    if (cart.total.subTotal < minShippingFree) return <></>

    return (
        <>
            <hr className={"primary-color"}/>
            <div className={"ps-3 pe-3 mt-2 mb-4"}>
                <p>{i18next.t('general.shippingFree').toString()} {text}
                    {bar >= 100 ?
                        <i className="fa-solid fa-circle-check text-green ms-1"></i> : <></>}
                </p>
                <div className="progress position-relative">
                    <div className="progress-bar" role="progressbar"
                         style={{width: `${bar}%`, backgroundColor: color}}
                         aria-valuenow={25} aria-valuemin={0} aria-valuemax={100}>
                    </div>
                </div>
            </div>
        </>
    )
}