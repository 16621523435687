import {useRouter} from "next/router";
import apiInternal from "../../../utils/apiInternal";
import i18next from "i18next";
import {tree} from "../../../utils/tree";
import categoryLinkService from "../../../services/category/categoryLinkService";
import React, {useEffect, useState} from "react";
import {routeParam} from "../../../utils/routeParam";
import Image from "next/image";
import languageService from "../../../services/languageService";
import {SwipeableDrawer} from "@mui/material";
import Link from "../../link/Link";
import {Swiper, SwiperSlide} from "swiper/react";
import {FreeMode, Mousewheel, Pagination} from "swiper/modules";
import variantService from "../../../services/product/variantService";
import cookieService from "../../../services/cookie/cookieService";
import ProductSlider from "../../slider/Product";
import {stripHtml} from "string-strip-html";
import {locale} from "../../../utils/locale";

let revision = '';

export default function NavigationOffcanvasMain() {
    const [isOpen, setIsOpen] = useState(false);
    const [categories, setCategories]: any = useState();
    const [category, setCategory]: any = useState();
    const [brands, setBrands] = useState([]);
    const [blogs, setBlogs] = useState([]);
    const [products, setProducts] = useState([]);
    const router = useRouter();

    const breakpoints = {
        0: {
            slidesPerView: 1
        },
        340: {
            slidesPerView: 1
        },
        768: {
            slidesPerView: 2
        },
        1200: {
            slidesPerView: 3
        },
    };

    let categoryId: any = (router.pathname.includes('/l') && router.query.category && !router.pathname.includes('/b/l')) ? routeParam(router.query.category.toString()) : null;

    useEffect(() => {
        if (!open) return;

        const id: any = categoryId === null ? tree(i18next.language) : null;
        const fn = async () => {
            const [resCategories, resCategory]: any = await Promise.all([getCategories(id, categoryId), getParentCategory(categoryId)]);
            
            if(resCategory.data.menuSpecial)getSpecialMenu();
            
            setCategories(resCategories.data)
            setCategory(resCategory.data)
        }

        fn().then();
    }, [isOpen])

    function getCategories(id?: number, parentId?: number) {
        return apiInternal().post('/api/v2/category/list', {id: id, parentId: parentId, legacyUrl: true});
    }

    function getCategory(id: number) {
        return apiInternal().post(`/api/v2/category/id`, {id: id, banner: true});
    }

    function getParentCategory(id: number) {
        if (!id) id = tree(i18next.language);
        return apiInternal().post(`/api/v2/category/parent`, {id: id, banner: true});
    }

    async function loadCategories(id: number) {
        let promises: any = [getCategories(id)];
        if (id) promises.push(getCategory(id))
        const [resCategories, resCategory] = await Promise.all(promises);
        
        if(resCategory.data.menuSpecial)getSpecialMenu();
        
        setCategories(resCategories.data);
        setCategory(resCategory.data);
    }

    function close() {
        setIsOpen(false);
        setCategory(null)
    }

    async function link(category: any) {
        const path: any = categoryLinkService.getLink(category);
        close();

        const resRevision = await apiInternal().post('/api/v2/git/revision');

        if (revision.length && resRevision.data !== revision) {
            console.warn('-- revision changed, full page load')
            return location.href = path;
        }

        revision = resRevision.data;

        await router.push(path);
    }

    const toggleDrawer = () =>
        (event: React.KeyboardEvent | React.MouseEvent) => {
            if (
                event &&
                event.type === 'keydown' &&
                ((event as React.KeyboardEvent).key === 'Tab' ||
                    (event as React.KeyboardEvent).key === 'Shift')
            ) {
                return;
            }
            setIsOpen(!isOpen);
        };

    function getNavigationItem(category: any) {
        if (category.childId) {
            return (
                <li key={category.id} className={"pt-3 pb-3 border-bottom"} role={"button"}
                    onClick={() => loadCategories(category.id)}>
                    <span>{category.name}</span>
                </li>
            )
        } else if (category.menuType){
            return (
                <li key={category.id}
                        className={"pt-3 pb-3 border-bottom"}>
                        {(category.menuType) ? <span className={"me-4 text-break"}><b>{category.name}</b></span> : <span className={"me-4 text-break"}>{category.name}</span>}
                        {(category.menuType === 'new_brand') ? (
                            <div className="pb-2 m-0 special-banner-container">
                                <Swiper
                                    key={'new-brands'}
                                    breakpoints={breakpoints}
                                    pagination={{clickable: true}}
                                    mousewheel={{forceToAxis: true}}
                                    freeMode={{sticky: true}}
                                    navigation={true}
                                    modules={[Mousewheel, FreeMode, Pagination]}
                                    className="pb-4 w-100">
                                     {brands.map((brand: any) =>(
                                        <SwiperSlide key={brand.id} className={'w-100 d-flex justify-content-center align-items-center'}>
                                            <Link href={`/${i18next.language}/${brand.link}`} className="w-80">
                                                <Image src={brand.image} className={'w-100 h-auto '}
                                                    height={80} width={200}
                                                    alt={brand.name} />
                                            </Link>
                                        </SwiperSlide>
                                        ))}
                                </Swiper>
                                <div className={'pt-3'}>
                                    <Link href={categoryLinkService.getLink({name: category.name, external:category.external, link:category.link, id:category.id, blog:category.blog})} onClick={() => setIsOpen(false)}
                                          className="special-menu-more">
                                        {i18next.t('general.showMore').toString()}
                                    </Link>
                                </div>
                            </div>
                            ) : (category.menuType === 'new_product') ? (
                                <div className="pb-2 mr-2">
                                    <div className="new-menu-product-slider" data-per-page={2} >
                                        <ProductSlider products={products} perPage={2} container={false} pt={'pt-0'} mt ={'mt-0'} informationOnly={false} />
                                    </div>

                                    <Link href={categoryLinkService.getLink({name: category.name, external:category.external, link:category.link, id:category.id, blog:category.blog})} onClick={() => setIsOpen(false)} className="special-menu-more">
                                        {i18next.t('general.showMore').toString()}
                                    </Link>
                                </div>
                            ) : (category.menuType === 'new_blog')?(
                                <div className="pb-2 m-0 mt-2 special-banner-container ">
                                    <Swiper
                                        key={'new-blogs'}
                                        breakpoints={breakpoints}
                                        pagination={{clickable: true}}
                                        mousewheel={{forceToAxis: true}}
                                        freeMode={{sticky: true}}
                                        navigation={true}
                                        modules={[Mousewheel, FreeMode, Pagination]}
                                        className="pb-4 w-100">
                                         {blogs.map((blog: any) =>(
                                            <SwiperSlide key={blog.id}>
                                                <Link href={`/${i18next.language}/${blog.link}`} className="">
                                                    <Image src={blog.image}
                                                           height={120}
                                                           width={200}
                                                           alt={''}
                                                           className="new-blog-div w-100 h-auto"/>
                                                    <p className="mb-0 p-1">
                                                        {blog.link}
                                                        {truncate(blog.title, 50)}
                                                    </p>
                                                </Link>
                                            </SwiperSlide>
                                        ))}
                                    </Swiper>
                                    <div className={'pt-3'}>
                                        <Link href={categoryLinkService.getLink({name: category.name, external:category.external, link:category.link, id:category.id, blog:category.blog})} onClick={() => setIsOpen(false)}
                                              className="special-menu-more">
                                            {i18next.t('general.showMore').toString()}
                                        </Link>
                                    </div>
                                </div>
                            ) : (category.menuType === 'banner') ? (
                                <div className="pb-2 pr-2 mt-2">
                                    <Link href={category.menuLink} onClick={() => setIsOpen(false)}>
                                        <div className="special-banner-container">
                                            <Image src={category.menuImage}
                                                    height={270}
                                                    width={270}
                                                    alt={category.name}
                                                    className="d-block special-banner"/>
                                            <p className="mt-2">
                                                <b>{stripHtml(category.menuTitle).result}</b>
                                            </p>
                                            <p>{stripHtml(category.menuText).result}</p>
                                        </div>
                                    </Link>

                                    <Link href={categoryLinkService.getLink({name: category.name, external:category.external, link:category.link, id:category.id, blog:category.blog})} onClick={() => setIsOpen(false)}
                                          className="special-menu-more">
                                        {i18next.t('general.showMore').toString()}
                                    </Link>
                                </div>
                            ) : <i className="fa-solid fa-circle-chevron-right position-absolute mt-2 top-25 end-0"/>}
                </li>
            )
        }
         else{
            return (
                <li key={category.id} onClick={() => link(category)}
                    className={"pt-3 pb-3 d-block position-relative border-bottom"}>
                    <span className={"me-4 text-break"}>{category.name}</span>
                    <i className="fa-solid fa-circle-chevron-right position-absolute mt-2 top-25 end-0"/>
                </li>
            )
        }
    }

    async function getSpecialMenu(){
        return Promise.all([
            getBrands(),
            getBlogs(),
            getProducts()
        ]);
    }

    async function getBrands() {
        const resBrands = await apiInternal().post('/api/v2/navigation/main/special/brands');
        setBrands(resBrands.data);
    }

    async function getBlogs() {
        const resBlogs = await apiInternal().post('/api/v2/navigation/main/special/blogs', {locale: i18next.language});
        setBlogs(resBlogs.data);
    }

    async function getProducts() {
        
        const resProducts = await apiInternal().post('/api/v2/navigation/main/special/products', {
            locale: locale(i18next.language),
            customerGroup: cookieService.getCustomerGroup()
        });

        await variantService.getVariants(resProducts.data, true, cookieService.getCustomerGroup(), i18next.language);
        setProducts(resProducts.data);
    }

    function truncate(str: string, maxStrLength: number){
        return (str.length > maxStrLength) ? str.slice(0, maxStrLength-1) + '...' : str;
      };

    return (
        <>
            <div className="text-center pe-4 d-lg-none header-icons offcanvas-menu-toggle"
                 onClick={toggleDrawer()}
                 role={"button"}>
                <i className="fa-solid fa-bars d-block"></i>
                <p className="sup-2">{i18next.t('general.menu').toString()}</p>
            </div>
            <SwipeableDrawer
                anchor={'left'}
                open={isOpen}
                disableSwipeToOpen={true}
                onClose={toggleDrawer()}
                onOpen={toggleDrawer()}>
                <div className="position-relative mui-offcanvas">
                    <div className="ps-3 pe-3 pt-4 pb-3 bg-dark text-white" onClick={() => setIsOpen(false)} role="button">
                        <i className="fa-solid fa-circle-xmark"></i>
                        <span className="ms-2 fw-bold">{i18next.t('general.close').toString()}</span>
                    </div>

                    {categories && (
                        <div className="p-3 position-relative h-auto">
                            {(category && category.id && !category.hideTop) && (
                                <>
                               
                                    <div className={"row border-bottom mb-4 mt-4 pb-3"}>
                                        <div className={"col-6"}>
                                            <div role={"button"}
                                                 onClick={() => loadCategories(category ? category.parentId : null)}
                                                 className={"pb-2 d-block"}>
                                                <i className="fa-solid fa-circle-chevron-left me-2"/>
                                                <span>{i18next.t('general.back').toString()}</span>
                                            </div>
                                        </div>
                                        <div className={"col-6"}>
                                            <div role={"button"}
                                                 onClick={() => loadCategories(tree(i18next.language))}
                                                 className={"pb-2 d-block"}>
                                                <i className="fa-solid fa-house me-2"></i>
                                                <span className={"fw-bold"}>{i18next.t('mainMenu.title').toString()}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={"mb-2 pb-3 d-block position-relative border-bottom"}>
                                        <div onClick={() => link(category)}>
                                    <span
                                        className={"fw-bold"}>{category.name} {i18next.t('general.overview').toString()}</span>
                                            <i className="fa-solid fa-circle-chevron-right position-absolute mt-1 end-0"/>
                                        </div>
                                    </div>
                                </>
                            )}

                            <ul className="list-unstyled">
                                {categories.map((category: any) => (getNavigationItem(category)))}
                            </ul>

                            
                            {category && category.banner && category.banner.menuImage ? (
                                <>
                                    <Link href={category.banner.menuLink} className={"mt-4 mb-md-5 row d-flex"}>
                                        <div className={"col-6 col-md-4"}>
                                            <Image src={category.banner.menuImage} alt={""} width={400} height={400}
                                                   className={"w-100 h-auto rounded-4"}/>
                                        </div>
                                        <div className={"col-6 col-md-8"}>
                                            <p className={"fw-bold"}>{category.banner.menuTitle}</p>
                                            <p dangerouslySetInnerHTML={{__html: category.banner.menuText}}></p>
                                            <p className={"text-decoration-underline"}>
                                                <span>{i18next.t('general.continue').toString()}</span>
                                                <i className="fa-solid fa-circle-chevron-right ms-2"></i>
                                            </p>
                                        </div>
                                    </Link>
                                </>
                            ) : <></>}

                            <p className={"fw-bold mt-4 mb-0 border-bottom pb-2 mb-3"}>{i18next.t('general.language').toString()}</p>
                            <div className="d-flex sup">
                                <div className="border-end flex-fill text-center">
                                    <div onClick={() => languageService.setLanguage('de', router)}
                                         className={`${i18next.language === 'de' && 'fw-bold'}`}
                                         role={"button"}>
                                        Deutsch
                                    </div>
                                </div>
                                <div className=" flex-fill text-center">
                                    <div onClick={() => languageService.setLanguage('en', router)}
                                         className={`${i18next.language === 'en' && 'fw-bold'}`}
                                         role={"button"}>
                                        English
                                    </div>
                                </div>
                                <div className="border-end flex-fill text-center">
                                    <div onClick={() => languageService.setLanguage('fr', router)}
                                         className={`${i18next.language === 'fr' && 'fw-bold'}`}
                                         role={"button"}>
                                        Français
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </SwipeableDrawer>
        </>
    )
}