import {Swiper, SwiperSlide} from 'swiper/react';
import ProductTile from "../product/Tile";
import React from "react";
import {FreeMode, Mousewheel, Pagination} from "swiper/modules";
import {convertBxAttributes} from "../../utils/bxAttributes";

export default function ProductSlider(
    {
        products,
        title,
        md = 3,
        lg = 4,
        xl = 5,
        container = true,
        pt = 'pt-4',
        mt = 'mt-1',
        perPage = null,
        informationOnly = false,
    }: any) {
    const breakpoints: any = {
        1: {
            slidesPerView: 1
        },
        340: {
            slidesPerView: 2
        },
        768: {
            slidesPerView: perPage ?? md
        },
        992: {
            slidesPerView: perPage ?? lg
        },
        1200: {
            slidesPerView: perPage ?? xl
        },
    }

    if (!products.length) return <></>

    function getTile(product: any) {
        return <ProductTile product={product} informationOnly={informationOnly}/>
    }

    return (
        <div className={`bg-highlight swiper-container overflow-hidden d-block ${mt}`} data-per-page={perPage}>
            <div className={`bg-white ${pt} pb-4 ${container && 'container'}`}>
                {title && (
                    <>
                        <h2 className="mb-3">{title}</h2>
                        <hr className={"primary-color"}/>
                    </>
                )}

                <Swiper pagination={{clickable: true}}
                        breakpoints={breakpoints}
                        mousewheel={{forceToAxis: true}}
                        freeMode={{sticky: true}}
                        modules={[Pagination, Mousewheel, FreeMode]}
                        className="product-slider pb-4 overflow-hidden d-block"
                        preventClicks={true}
                        loop={false}>
                    {products.map((product: any, index: number) => (
                        <SwiperSlide {...convertBxAttributes(product)} key={index}>
                            {getTile(product)}
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </div>
    )
}
