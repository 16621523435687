type Breakpoint = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
const viewportWidths: Map<Breakpoint, string> = new Map([
    ['xxl', '1400px'],
    ['xl', '1200px'],
    ['lg', '992px'],
    ['md', '768px'],
    ['sm', '576px']
]);

export function imageSizes(imageWidths: Partial<Record<Breakpoint, string>>): string {
    const sizes: string[] = [];
    viewportWidths.forEach((viewportWidth, breakpoint) => {
        const imageWidth = imageWidths[breakpoint];
        if (imageWidth) {
            sizes.push(`(min-width: ${viewportWidth}) ${imageWidth}`);
        }
    });
    if (imageWidths.xs) {
        sizes.push(imageWidths.xs);
    }
    return sizes.join(',');
}
