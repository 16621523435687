import React, {useState} from "react";
import apiClient from "../../../utils/apiClient";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CartOffCanvasGiftItem from "./Gift/Item";
import i18next from "i18next";

interface Props {
    lineItem: any
}

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    maxHeight: '90%',
    bgcolor: 'background.paper',
    border: '1px solid #000',
    borderRadius: '10px',
    boxShadow: 24,
    p: 4,
    overflow: "auto",
    outline: 0,
    '@media (min-width: 768px)': {
        maxHeight: '600px',
        width: '500px'
    },
};

export default function CartOffCanvasGift({lineItem}: Props) {
    const [list, setList]: any = useState([]);
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    if (!lineItem.storedGiftId || lineItem.giftId) return <></>

    const onClick = async () => {
        const result: any = await apiClient.post('/api/gift/list', {id: lineItem.detailId});
        setList(result.data);
        handleOpen();
    }

    return (
        <>
            <div className={"d-flex flex-row align-items-center mb-3"} role={"button"} onClick={onClick}>
                <i className="fa-sharp fa-light fa-gift fs-9 me-2 text-green"></i>
                <span className={"text-green fs-9"}>Geschenk vorhanden!</span>
            </div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <Box sx={style}>
                    <div className={"d-flex flex-row justify-content-between"} onClick={handleClose} role={"button"}>
                        <h2>{i18next.t('giftInfo.title').toString()}</h2>
                        <i className="fa-sharp fa-regular fa-xmark fs-5"></i>
                    </div>
                    <hr/>

                    <Typography id="modal-modal-description" sx={{mt: 2}}>
                        {list.map((item: any, index: number) => (
                            <CartOffCanvasGiftItem item={item} showTitle={false}
                                                   className={`${index < (list.length - 1) && 'border-bottom'}`}/>
                        ))}
                    </Typography>
                </Box>
            </Modal>
        </>
    )
}