import React from "react";
import Image from "next/image";
import i18next from "i18next";
import imageService from "../../../../services/product/imageService";

interface Props {
    item: any;
    showTitle?: boolean,
    className?: string
}

export default function CartOffCanvasGiftItem({item, showTitle = true, className = ''}: Props) {
    function getProductLabel(quantity: number) {
        return quantity > 1 ? i18next.t('general.products').toString() : i18next.t('product.title').toString();
    }

    function getCover(product: any) {
        if (product.cover) return product.cover;
        return imageService.getImage(product.imgUrls, product.orderNumber)
    }

    return (
        <div className={`pt-3 pb-3 h-100 mb-3 ${className}`}>
            <div key={item.gift.id} className={`row w-100 ms-0 me-0`}>
                <div className="col-4 col-md-3 text-center">
                    <Image src={getCover(item.gift.product)}
                           height={100}
                           width={100}
                           alt={`${i18next.t('gift.imgAlt').toString()} ${item.gift.product.name}`}
                           style={{maxWidth: "90px"}}
                           className={"w-100 h-auto"}/>
                </div>
                <div className="col-8 col-md-9 sup position-relative">
                    {showTitle && (
                        <p>
                            <i className="fa-solid fa-gift pe-1"></i>
                            <span className="fw-bold">{i18next.t('giftInfo.title').toString()}</span>
                        </p>
                    )}

                    <div className={`gift-rules`}>
                        {(item.rules.map((rule: any) => (
                            <p key={rule.id}>
                                {(rule.type === 1) && (
                                    <>
                                        {(item.gift.amount > 0) ? (
                                            <span dangerouslySetInnerHTML={{
                                                __html: i18next.t('giftRule.type1Amount', {
                                                    ruleName: rule.name,
                                                    amount: item.gift.amount,
                                                    productName: item.gift.product.name
                                                }).toString()
                                            }}/>
                                        ) : (
                                            <span dangerouslySetInnerHTML={{
                                                __html: i18next.t('giftRule.type1Quantity', {
                                                    ruleName: rule.name,
                                                    quantity: item.gift.quantity,
                                                    productName: item.gift.product.name
                                                }).toString()
                                            }}/>
                                        )}
                                    </>
                                )}

                                {(rule.type === 2) && (
                                    <>
                                        {(item.gift.amount > 0) ? (
                                            <>
                                                <span dangerouslySetInnerHTML={{
                                                    __html: i18next.t('giftRule.type2Amount', {
                                                        ruleName: rule.name,
                                                        amount: item.gift.amount,
                                                        label: getProductLabel(item.gift.quantity),
                                                        productName: item.gift.product.name
                                                    }).toString()
                                                }}/>
                                            </>
                                        ) : (
                                            <>
                                                <span dangerouslySetInnerHTML={{
                                                    __html: i18next.t('giftRule.type2Quantity', {
                                                        ruleName: rule.name,
                                                        label: getProductLabel(item.gift.quantity),
                                                        quantity: item.gift.quantity,
                                                        productName: item.gift.product.name
                                                    }).toString()
                                                }}/>
                                            </>
                                        )}
                                    </>
                                )}

                                {(rule.type === 3) && (
                                    <>
                                        {(item.gift.amount > 0) ? (
                                            <>
                                                <span dangerouslySetInnerHTML={{
                                                    __html: i18next.t('giftRule.type3Amount', {
                                                        ruleName: rule.name,
                                                        amount: item.gift.amount,
                                                        productName: item.gift.product.name
                                                    }).toString()
                                                }}/>
                                            </>
                                        ) : (
                                            <>
                                                <span dangerouslySetInnerHTML={{
                                                    __html: i18next.t('giftRule.type3Quantity', {
                                                        ruleName: rule.name,
                                                        label: getProductLabel(item.gift.quantity),
                                                        quantity: item.gift.quantity,
                                                        productName: item.gift.product.name
                                                    }).toString()
                                                }}/>
                                            </>
                                        )}
                                    </>
                                )}

                                {(rule.type === 4) && (
                                    <>
                                        {(item.gift.amount > 0) ? (
                                            <>
                                                <span dangerouslySetInnerHTML={{
                                                    __html: i18next.t('giftRule.type4Amount', {
                                                        ruleName: rule.name,
                                                        amount: item.gift.amount,
                                                        productName: item.gift.product.name
                                                    }).toString()
                                                }}/>
                                            </>
                                        ) : (
                                            <span dangerouslySetInnerHTML={{
                                                __html: i18next.t('giftRule.type4Quantity', {
                                                    ruleName: rule.name,
                                                    label: getProductLabel(item.gift.quantity),
                                                    quantity: item.gift.quantity,
                                                    productName: item.gift.product.name
                                                }).toString()
                                            }}/>
                                        )}
                                    </>
                                )}
                            </p>
                        )))}
                    </div>
                </div>
            </div>
        </div>
    )
}
