import { v4 as uuidv4 } from 'uuid';
import {setCookie} from "cookies-next";
import moment from "moment";
import cookieOptions from "../../utils/cookieOptions";
import type {ServerResponse} from "http";
import {GetServerSidePropsContext} from "next";

type Request = GetServerSidePropsContext["req"];

class BxCookieService {
  getProfileId(request: Request, response: ServerResponse) {
    return this.getOrGenerateId(request, response, 'cemv', moment().add(1, 'year').toDate())
  }

  getSessionId(request: Request, response: ServerResponse) {
    return this.getOrGenerateId(request, response, 'cems');
  }

  private getOrGenerateId(request: Request, response: ServerResponse, cookieName: string, expires?: Date) {
    const id = request.cookies[cookieName] ?? uuidv4();
    setCookie(cookieName, id, { ...cookieOptions(expires), req: request, res: response });
    return id;
  }
}

const bxCookieService = new BxCookieService();
export default bxCookieService;
